import { Box, Button, Flex, Text } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useState } from "react"
import { IoLogOut, IoRefresh } from "react-icons/io5"
import { useAdminAuthContext } from "src/components/context/adminAuth"
import { ICompanyV2 } from "src/domain/entities"
import { companyExpiresAtUpdateService } from "src/domain/services/company/companyExpiresAtUpdateService"
import { useCompanyListService } from "src/hooks/company/useCompanyListService"
import { CompanyListController } from "./CompanyList"

export const CompaniesPage: FC = () => {
	const [companyList, isLoading, fetchCompanyList] = useCompanyListService()
	const [editingCompanyId, setEditingCompanyId] = useState<string>()

	const handleFetchCompanyList = useCallback(() => {
		fetchCompanyList({ include: { userVerifiedMobile: true } })
	}, [fetchCompanyList])

	useEffect(() => {
		handleFetchCompanyList()
	}, [handleFetchCompanyList])

	const { token } = useAdminAuthContext()
	const handleDateUpdateSubmit = useCallback(
		async (company: ICompanyV2, date: string) => {
			if (!token) return null
			await companyExpiresAtUpdateService(
				{ filter: { id: company.id }, update: { expiresAt: date } },
				token,
			)
			setEditingCompanyId(undefined)
			handleFetchCompanyList()
			return
		},
		[handleFetchCompanyList, token],
	)

	const handleLogOut = useCallback(() => {
		window.location.href = "/admin/login"
	}, [])

	return (
		<>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Companies
					</Text>
					<Box>
						<Flex gridGap={2}>
							<Button
								leftIcon={<IoRefresh />}
								colorScheme={"blue"}
								onClick={handleFetchCompanyList}
								isLoading={isLoading}
							>
								Refresh
							</Button>
							<Button
								leftIcon={<IoLogOut />}
								colorScheme={"red"}
								variant={"ghost"}
								onClick={handleLogOut}
							>
								Log out
							</Button>
						</Flex>
					</Box>
				</Flex>
			</Box>
			<CompanyListController
				list={companyList}
				isLoading={isLoading}
				handleSubmit={handleDateUpdateSubmit}
				editingCompanyId={editingCompanyId}
				setEditingCompanyId={setEditingCompanyId}
			/>
		</>
	)
}
