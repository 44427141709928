import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	FormControl,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Radio,
	RadioGroup,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import moment from "moment"
import { ComponentProps, Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { PartyAddButton } from "src/components/shared/PartyAddButton"
import {
	getProductOption,
	ProductAddButton,
} from "src/components/shared/ProductAddButton"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputDescription } from "src/components/ui/InputDescription"
import { InputLabel } from "src/components/ui/InputLabel"
import { EJobWorkItemType } from "src/domain/api/jobWorkItem/EJobWorkItemType"
import { IProductV1, ISubPartyV1 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IJobWorkItemSlipAddFormFields } from "./IJobWorkItemSlipAddFormFields"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	type: EItemType
	subPartyList: ISubPartyV1[]
	productList: IProductV1[]
	isProductListLoading?: boolean
	defaultSelectedSubParty?: ISubPartyV1
	defaultSelectedProduct?: IProductV1
	defaultPrefix?: string
	prefillData?: Partial<IJobWorkItemSlipAddFormFields>
	handleSubmit: FormikOnSubmit<IJobWorkItemSlipAddFormFields>
	onProductAddSuccess?: Function
	setDefaultSelectedProduct?: Function
	onPartyAddSuccess?: Function
	fetchSlipNextNo: (
		setNextNo: Dispatch<SetStateAction<number>>,
		payload: { issueDate: string; prefix: string },
	) => void
}

export const JobWorkItemSlipAddDrawerFormView: FC<Props> = ({
	type,
	subPartyList,
	defaultSelectedSubParty,
	defaultSelectedProduct,
	productList,
	isProductListLoading,
	defaultPrefix,
	handleSubmit,
	fetchSlipNextNo,
	onProductAddSuccess,
	setDefaultSelectedProduct,
	onPartyAddSuccess,
	prefillData,
	...rest
}) => {
	const defaultDate = moment().format("YYYY-MM-DD")

	const [issueDate, setIssueDate] = useState(defaultDate)
	const [prefix, setPrefix] = useState(defaultPrefix ?? "SL")
	const [slipNo, setSlipNo] = useState<number>(1)

	let headerLabel = type === EItemType.INWARD ? "Add In Slip" : "Add Out Slip"

	const subPartyLabel =
		type === EItemType.INWARD ? "Select Supplier" : "Select Recipient"
	const subPartyDescription = `Party / Sub-Party ${
		type === EItemType.INWARD ? "from where goods came in" : "to whom goods going out"
	}`

	function getSubPartyLabel(subPartyId: string) {
		const subParty = subPartyList.find((subParty) => subParty.id === subPartyId)
		if (!subParty) return "unknown"

		let label = subParty.name
		if (!subParty.isDefault) label += ` (${subParty.party?.name})`
		return label
	}

	useEffect(() => {
		fetchSlipNextNo(setSlipNo, { issueDate, prefix })
	}, [fetchSlipNextNo, issueDate, prefix])

	return (
		<Formik<IJobWorkItemSlipAddFormFields>
			initialValues={{
				itemType:
					type === EItemType.INWARD
						? EJobWorkItemType.IN
						: EJobWorkItemType.OUT,
				subPartyId: defaultSelectedSubParty?.id || "",
				productId: defaultSelectedProduct?.id || "",
				issueDate,
				prefix,
				slipNo,
				workRate: 0,
				lossPercentage: 0,
				bagQuantity: 0,
				grossQuantity: 0,
				note: "",
				isLossInclusive: true,
				marketRate: 0,
				...prefillData,
			}}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => (
				<DrawerForm
					size="lg"
					headerLabel={headerLabel}
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack marginX={"auto"}>
						<Flex gridGap={3}>
							{/* Issue date */}
							<FormControl flex={1}>
								<InputLabel label="Issue Date" />
								<Input
									name="issueDate"
									type="date"
									autoFocus
									value={values.issueDate}
									onChange={(e) => {
										setIssueDate(e.target.value ?? defaultDate)
									}}
								/>
							</FormControl>
							{/* Sub Party */}
							<Flex flex={3} alignItems="center">
								<FormControl flex={1}>
									<InputLabel label={subPartyLabel} />
									<ReactSelect
										name="subPartyId"
										onChange={(newValue) => {
											setFieldValue(
												"subPartyId",
												(newValue as SelectOption).value,
											)
										}}
										options={subPartyList.map((subParty) => ({
											label: getSubPartyLabel(subParty.id),
											value: subParty.id,
										}))}
										isSearchable
										defaultValue={values.subPartyId}
									/>
									<InputDescription>
										{subPartyDescription}
									</InputDescription>
								</FormControl>
								<Box marginLeft={2}>
									<PartyAddButton
										onSuccess={async (party) => {
											await onPartyAddSuccess?.(party)
											setFieldValue("subPartyId", party.id)
										}}
									/>
								</Box>
							</Flex>
						</Flex>

						<Flex gridGap={3}>
							{/* Slip Prefix */}
							<FormControl flex={1}>
								<InputLabel label="Slip Prefix" />
								<Input
									name="prefix"
									maxLength={10}
									value={values.prefix}
									onChange={(e) => {
										setPrefix(e.target.value ?? "SL")
									}}
								/>
							</FormControl>

							{/* Slip no */}
							<FormControl flex={1}>
								<InputLabel label="Slip No" />
								<Input
									name="slipNo"
									maxLength={10}
									required
									value={values.slipNo}
									onChange={handleChange}
								/>
							</FormControl>
							<Flex flex={3} alignItems="flex-end">
								{/* Product */}
								<FormControl flex={1}>
									<InputLabel label="Select Product" />
									<ReactSelect
										name="productId"
										onChange={(newValue) => {
											setFieldValue(
												"productId",
												(newValue as SelectOption).value,
											)
										}}
										value={getProductOption(
											values.productId,
											productList,
										)}
										isLoading={isProductListLoading}
										options={productList.map((product) => ({
											label: product.name,
											value: product.id,
										}))}
										isSearchable
										defaultValue={values.productId}
									/>
								</FormControl>
								<Box marginLeft={2}>
									<ProductAddButton
										onSuccess={async (product) => {
											await onProductAddSuccess?.()
											setDefaultSelectedProduct?.(product)
											setFieldValue("productId", product.id)
										}}
									/>
								</Box>
							</Flex>
						</Flex>

						<Flex gridGap={3}>
							{/* Gross Quantity */}
							<FormControl flex={1}>
								<InputLabel label={"Gross Quantity"} />
								<InputGroup>
									<Input
										name="grossQuantity"
										required
										value={values.grossQuantity}
										onChange={handleChange}
									/>
									<InputRightAddon children="KG" />
								</InputGroup>
							</FormControl>
							{/* Bag Quantity */}
							<FormControl flex={1}>
								<InputLabel label="Bag Weight" />
								<InputGroup>
									<Input
										name="bagQuantity"
										required
										value={values.bagQuantity}
										onChange={handleChange}
									/>
									<InputRightAddon children="KG" />
								</InputGroup>
							</FormControl>
							{/* Labour Rate */}
							<FormControl flex={1}>
								<InputLabel label="Labour Rate" suffixLabel="(per KG)" />
								<InputGroup>
									<InputLeftAddon children="₹" />
									<Input
										name="workRate"
										required
										value={values.workRate}
										onChange={handleChange}
									/>
								</InputGroup>
							</FormControl>
						</Flex>

						{/* Note */}
						<FormControl>
							<InputLabel label="Note" suffixLabel="(Optional)" />
							<Textarea
								name="note"
								onChange={handleChange}
								value={values.note ?? ""}
							/>
							<ErrorMessage component={ErrorMessageField} name="note" />
						</FormControl>
						{/* Loss Percentage */}
						<Accordion allowToggle>
							<AccordionItem>
								<AccordionButton>
									<Flex width={"full"} justify={"space-between"}>
										<Box textAlign="left">Burn Loss</Box>
										<AccordionIcon />
									</Flex>
								</AccordionButton>

								<AccordionPanel>
									<Flex align={"center"} gridGap={4}>
										<FormControl flex={2} marginLeft={2}>
											<InputGroup>
												<Input
													name="lossPercentage"
													required
													value={values.lossPercentage}
													onChange={handleChange}
												/>
												<InputRightAddon children="%" />
											</InputGroup>
										</FormControl>
										<FormControl flex={8}>
											<RadioGroup
												onChange={(val) =>
													setFieldValue(
														"isLossInclusive",
														val === "1",
													)
												}
												value={values.isLossInclusive ? "1" : "0"}
												paddingLeft={2}
											>
												<Flex direction="row">
													<Box flex={1}>
														<Radio value="1">
															Is Inclusive
														</Radio>
														<InputDescription>
															This will reduce the loss from
															the gross quantity. (For
															Extrusions)
														</InputDescription>
													</Box>
													<Box flex={1}>
														<Radio value="0">
															Is Exclusive
														</Radio>
														<InputDescription>
															This will add up onto the
															gross quantity. (For Bhatthi)
														</InputDescription>
													</Box>
												</Flex>
											</RadioGroup>
										</FormControl>
									</Flex>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
						{/* Market Rate */}
						<Accordion allowToggle>
							<AccordionItem>
								<AccordionButton>
									<Flex width={"full"} justify={"space-between"}>
										<Box textAlign="left">Market Rate</Box>
										<AccordionIcon />
									</Flex>
								</AccordionButton>

								<AccordionPanel>
									<FormControl maxWidth={"fit-content"}>
										<InputGroup>
											<InputLeftAddon children="₹" />
											<Input
												name="marketRate"
												required
												value={values.marketRate}
												onChange={handleChange}
											/>
										</InputGroup>
										<InputDescription>
											This input is just for reference purpose only.
										</InputDescription>
									</FormControl>
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</Stack>
				</DrawerForm>
			)}
		</Formik>
	)
}
