import { IJobWorkItemSlipV2 } from "src/domain/entities"
import { ApiRequester } from "../../ApiRequester"
import { EJobWorkItemType } from "../EJobWorkItemType"

interface RequestDataShape {
	itemType: EJobWorkItemType
	subPartyId: string
	productId: string
	workRate: number
	marketRate: number
	lossPercentage: number
	isLossInclusive: boolean
	issueDate: string
	prefix: string
	slipNo: number
	grossQuantity: number
	bagQuantity: number
	note: string
	include?: { jobWorkItem?: boolean }
}

interface ResponseDataShape {
	success: boolean
	data: IJobWorkItemSlipV2
}

export async function jobWorkItemSlipAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/jobWorkItem/slip/add/v2"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response.data
}
