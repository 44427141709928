import { useDisclosure } from "@chakra-ui/react"
import { ComponentProps, FC, useState } from "react"
import { ItemSlipUpdateDrawerFormController } from "src/components/entries/itemSlip/ItemSlipUpdateDrawerForm"
import { JobWorkItemSlipAddDrawerFormController } from "src/components/entries/itemSlip/JobWorkItemSlipAddDrawerForm/JobWorkItemSlipAddDrawerFormController"
import { EJobWorkItemType } from "src/domain/api/jobWorkItem/EJobWorkItemType"
import { IJobWorkItemSlipV2, IJobWorkItemV2 } from "src/domain/entities"
import { jobWorkItemUpdateService } from "src/domain/services/jobWorkItem/jobWorkItemUpdateService"
import { useAuth } from "src/hooks"
import { EItemType } from "../../../../../entries/EItemType"
import { ItemSlipDeleteDialogController } from "../../../../../entries/itemSlip/ItemSlipDeleteDialogController"
import { usePartyDetailsPageContext } from "../../../PartyDetailsPageContext"
import { IItemUpdateFormFields } from "./IItemUpdateFormFields"
import { ItemSlipListDrawer } from "./ItemSlipListDrawer"

interface Props extends ComponentProps<typeof ItemSlipListDrawer> {}

export const ItemSlipListController: FC<Props> = (props) => {
	const { token } = useAuth()
	const itemSlipEditDisclosure = useDisclosure()
	const itemSlipAddDisclosure = useDisclosure()
	const [selectedJobWorkItem, setSelectedJobWorkItem] = useState<IJobWorkItemV2 | null>(
		null,
	)
	const [selectedItemSlip, setSelectedItemSlip] = useState<IJobWorkItemSlipV2 | null>(
		null,
	)
	const { refreshPageData } = usePartyDetailsPageContext()

	const [isItemSlipDeleteDialogOpen, setIsItemSlipDeleteDialogOpen] = useState(false)

	const handleItemSlipEditClick = (
		slip: IJobWorkItemSlipV2,
		jobWorkItem: IJobWorkItemV2,
	) => {
		itemSlipEditDisclosure.onOpen()
		setSelectedItemSlip(slip)
		setSelectedJobWorkItem(jobWorkItem)
	}
	const handleItemSlipDeleteClick = (
		slip: IJobWorkItemSlipV2,
		jobWorkItem: IJobWorkItemV2,
	) => {
		setSelectedItemSlip(slip)
		setSelectedJobWorkItem(jobWorkItem)
		setIsItemSlipDeleteDialogOpen(true)
	}

	const handleItemDetailsFormSubmit = async (values: IItemUpdateFormFields) => {
		const filter = {
			id: props.item.id,
			itemType:
				props.type === EItemType.INWARD
					? EJobWorkItemType.IN
					: EJobWorkItemType.OUT,
		}
		await jobWorkItemUpdateService(
			{
				filter,
				update: {
					workRate: values.workRate,
					lossPercentage: values.lossPercentage,
					isLossInclusive: values.isLossInclusive,
					marketRate: values.marketRate,
				},
			},
			token,
		)

		refreshPageData()
	}

	return (
		<>
			<ItemSlipListDrawer
				{...props}
				onEditClick={handleItemSlipEditClick}
				onDeleteClick={handleItemSlipDeleteClick}
				onItemUpdateSubmit={handleItemDetailsFormSubmit}
				onAddClick={itemSlipAddDisclosure.onOpen}
			/>
			{selectedItemSlip && selectedJobWorkItem && (
				<ItemSlipUpdateDrawerFormController
					{...itemSlipEditDisclosure}
					type={props.type}
					jobWorkItem={selectedJobWorkItem}
					slip={selectedItemSlip}
					onSuccess={() => {
						itemSlipEditDisclosure.onClose()
						refreshPageData()
						setSelectedItemSlip(null)
						setSelectedJobWorkItem(null)
					}}
				/>
			)}
			{selectedItemSlip && selectedJobWorkItem && (
				<ItemSlipDeleteDialogController
					isOpen={isItemSlipDeleteDialogOpen}
					setIsOpen={setIsItemSlipDeleteDialogOpen}
					type={props.type}
					slip={{ ...selectedItemSlip, jobWorkItem: selectedJobWorkItem }}
					onSuccess={() => {
						refreshPageData()
						setSelectedItemSlip(null)
						setSelectedJobWorkItem(null)
					}}
				/>
			)}
			{(props.type === EItemType.INWARD || props.type === EItemType.OUTWARD) &&
				itemSlipAddDisclosure.isOpen && (
					<JobWorkItemSlipAddDrawerFormController
						{...itemSlipAddDisclosure}
						onSuccess={() => {
							itemSlipAddDisclosure.onClose()
							refreshPageData()
						}}
						type={props.type}
						prefillData={{
							productId: props.item.product?.id,
							subPartyId: props.item.subPartyMonth?.subParty?.id,
							workRate: props.item.workRate,
							lossPercentage: props.item.lossPercentage,
							marketRate: props.item.marketRate,
						}}
					/>
				)}
		</>
	)
}
