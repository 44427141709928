import { FC } from "react"
import { BlankStateSection } from "src/components/shared/BlankStateSection"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { ICompanyV2 } from "src/domain/entities"
import { CompanyListView } from "."

interface Props {
	list: ICompanyV2[]
	isLoading: boolean
	handleSubmit: (company: ICompanyV2, date: string) => void
	editingCompanyId?: string
	setEditingCompanyId: (id?: string) => void
}

export const CompanyListController: FC<Props> = ({ isLoading, list, ...rest }) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No companies found." />
	}
	list = list.sort((a, b) => b.createdAt - a.createdAt)

	return <CompanyListView list={list} {...rest} />
}
