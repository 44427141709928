import { useDisclosure } from "@chakra-ui/react"
import { FC, useState } from "react"
import { BillDeleteDialogController } from "src/components/entries/bill/BillDeleteDialogController"
import { BillUpdateDrawerFormController } from "src/components/entries/bill/BillUpdateDrawerForm"
import { CashReceiptDeleteDialogController } from "src/components/entries/cashReceipt/CashReceiptDeleteDialogController"
import { ItemSlipUpdateDrawerFormController } from "src/components/entries/itemSlip/ItemSlipUpdateDrawerForm"
import { JobWorkBillDeleteDialogController } from "src/components/entries/jobWorkBill/JobWorkBillDeleteDialogController"
import { JobWorkBillUpdateDrawerFormController } from "src/components/entries/jobWorkBill/JobWorkBillUpdateDrawerForm"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import {
	ICashReceiptV2,
	IInwardCashReceiptV1,
	IJobWorkBillV2,
	IJobWorkItemSlipV2,
	IOutwardCashReceiptV1,
	IBillV2,
	IJobWorkItemV2,
} from "src/domain/entities"
import { CashReceiptUpdateDrawerFormController } from "../../../entries/cashReceipt/CashReceiptUpdateDrawerForm"
import { EItemType } from "../../../entries/EItemType"
import { ItemSlipDeleteDialogController } from "../../../entries/itemSlip/ItemSlipDeleteDialogController"
import { usePartyDetailsPageContext } from "../PartyDetailsPageContext"
import { PartyMonthSlipListView } from "./PartyMonthSlipListView"

export const PartyMonthSlipListController: FC = () => {
	const {
		statement,
		isStatementLoading,
		selectedSubParty,
		selectedPartyMonth,
		refreshPageData,
	} = usePartyDetailsPageContext()

	const [selectedItemType, setSelectedItemType] = useState<EItemType | null>(null)

	const itemSlipEditDisclosure = useDisclosure()
	const [selectedJobWorkItem, setSelectedJobWorkItem] = useState<IJobWorkItemV2 | null>(
		null,
	)
	const [selectedItemSlip, setSelectedItemSlip] = useState<IJobWorkItemSlipV2 | null>(
		null,
	)
	const [isItemSlipDeleteDialogOpen, setIsItemSlipDeleteDialogOpen] = useState(false)

	const cashReceiptEditDisclosure = useDisclosure()
	const [selectedCashReceipt, setSelectedCashReceipt] = useState<
		IInwardCashReceiptV1 | IOutwardCashReceiptV1 | null
	>(null)
	const [isCashReceiptDeleteDialogOpen, setIsCashReceiptDeleteDialogOpen] =
		useState(false)

	const jobWorkBillEditDisclosure = useDisclosure()
	const [selectedJobWorkBill, setSelectedJobWorkBill] = useState<IJobWorkBillV2 | null>(
		null,
	)
	const [isJobWorkBillDeleteDialogOpen, setIsJobWorkBillDeleteDialogOpen] =
		useState(false)

	const billEditDisclosure = useDisclosure()
	const [selectedBill, setSelectedBill] = useState<IBillV2 | null>(null)
	const [isBillDeleteDialogOpen, setIsBillDeleteDialogOpen] = useState(false)

	const handleItemSlipEditClick = (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		jobWorkItem: IJobWorkItemV2,
	) => {
		setSelectedItemSlip(slip)
		setSelectedItemType(type)
		setSelectedJobWorkItem(jobWorkItem)
		itemSlipEditDisclosure.onOpen()
	}
	const handleItemSlipDeleteClick = (
		slip: IJobWorkItemSlipV2,
		type: EItemType,
		jobWorkItem: IJobWorkItemV2,
	) => {
		setSelectedItemSlip(slip)
		setSelectedItemType(type)
		setSelectedJobWorkItem(jobWorkItem)
		setIsItemSlipDeleteDialogOpen(true)
	}
	const handleCashReceiptEditClick = (receipt: ICashReceiptV2, type: EItemType) => {
		setSelectedCashReceipt(receipt)
		setSelectedItemType(type)
		cashReceiptEditDisclosure.onOpen()
	}
	const handleCashReceiptDeleteClick = (receipt: ICashReceiptV2, type: EItemType) => {
		setSelectedCashReceipt(receipt)
		setSelectedItemType(type)
		setIsCashReceiptDeleteDialogOpen(true)
	}

	const handleJobWorkBillEditClick = (jobWorkBill: IJobWorkBillV2, type: EItemType) => {
		setSelectedJobWorkBill(jobWorkBill)
		setSelectedItemType(type)
		jobWorkBillEditDisclosure.onOpen()
	}
	const handleJobWorkBillDeleteClick = (
		jobWorkBill: IJobWorkBillV2,
		type: EItemType,
	) => {
		setSelectedItemType(type)
		setSelectedJobWorkBill(jobWorkBill)
		setIsJobWorkBillDeleteDialogOpen(true)
	}

	const handleBillEditClick = (saleBill: IBillV2, type: EItemType) => {
		setSelectedBill(saleBill)
		setSelectedItemType(type)
		billEditDisclosure.onOpen()
	}
	const handleBillDeleteClick = (saleBill: IBillV2, type: EItemType) => {
		setSelectedBill(saleBill)
		setSelectedItemType(type)
		setIsBillDeleteDialogOpen(true)
	}

	if (isStatementLoading) return <CenteredSpinner />

	if (!statement || !selectedPartyMonth) return null

	return (
		<>
			<PartyMonthSlipListView
				selectedSubParty={selectedSubParty}
				partyMonth={selectedPartyMonth}
				statement={statement}
				onItemSlipEditClick={handleItemSlipEditClick}
				onItemSlipDeleteClick={handleItemSlipDeleteClick}
				onCashReceiptEditClick={handleCashReceiptEditClick}
				onCashReceiptDeleteClick={handleCashReceiptDeleteClick}
				onJobWorkBillEditClick={handleJobWorkBillEditClick}
				onJobWorkBillDeleteClick={handleJobWorkBillDeleteClick}
				onBillEditClick={handleBillEditClick}
				onBillDeleteClick={handleBillDeleteClick}
			/>
			{(selectedItemType === EItemType.INWARD ||
				selectedItemType === EItemType.OUTWARD) && (
				<>
					{selectedItemSlip && selectedJobWorkItem && (
						<ItemSlipUpdateDrawerFormController
							{...itemSlipEditDisclosure}
							type={selectedItemType}
							jobWorkItem={selectedJobWorkItem}
							slip={selectedItemSlip}
							onSuccess={() => {
								itemSlipEditDisclosure.onClose()
								refreshPageData()
								setSelectedItemSlip(null)
							}}
						/>
					)}
					{selectedItemSlip && isItemSlipDeleteDialogOpen && (
						<ItemSlipDeleteDialogController
							isOpen={isItemSlipDeleteDialogOpen}
							setIsOpen={setIsItemSlipDeleteDialogOpen}
							type={selectedItemType}
							slip={selectedItemSlip}
							onSuccess={() => {
								refreshPageData()
								setSelectedItemSlip(null)
							}}
						/>
					)}
				</>
			)}
			{(selectedItemType === EItemType.INWARD_CASH ||
				selectedItemType === EItemType.OUTWARD_CASH) && (
				<>
					{selectedCashReceipt && (
						<CashReceiptUpdateDrawerFormController
							{...cashReceiptEditDisclosure}
							type={selectedItemType}
							slip={selectedCashReceipt}
							onSuccess={() => {
								cashReceiptEditDisclosure.onClose()
								refreshPageData()
								setSelectedCashReceipt(null)
							}}
						/>
					)}
					{selectedCashReceipt && isCashReceiptDeleteDialogOpen && (
						<CashReceiptDeleteDialogController
							isOpen={isCashReceiptDeleteDialogOpen}
							setIsOpen={setIsCashReceiptDeleteDialogOpen}
							type={selectedItemType}
							slip={selectedCashReceipt}
							onSuccess={() => {
								refreshPageData()
								setSelectedCashReceipt(null)
							}}
						/>
					)}
				</>
			)}
			{(selectedItemType === EItemType.INWARD_JOBWORK_BILL ||
				selectedItemType === EItemType.OUTWARD_JOBWORK_BILL) && (
				<>
					{selectedJobWorkBill && (
						<JobWorkBillUpdateDrawerFormController
							{...jobWorkBillEditDisclosure}
							type={selectedItemType}
							bill={selectedJobWorkBill}
							onSuccess={() => {
								jobWorkBillEditDisclosure.onClose()
								refreshPageData()
								setSelectedJobWorkBill(null)
							}}
						/>
					)}
					{selectedJobWorkBill && isJobWorkBillDeleteDialogOpen && (
						<JobWorkBillDeleteDialogController
							isOpen={isJobWorkBillDeleteDialogOpen}
							setIsOpen={setIsJobWorkBillDeleteDialogOpen}
							bill={selectedJobWorkBill}
							type={selectedItemType}
							onSuccess={() => {
								refreshPageData()
								setSelectedJobWorkBill(null)
							}}
						/>
					)}
				</>
			)}
			{(selectedItemType === EItemType.SALE_BILL ||
				selectedItemType === EItemType.PURCHASE_BILL) && (
				<>
					{selectedBill && (
						<BillUpdateDrawerFormController
							{...billEditDisclosure}
							type={selectedItemType}
							bill={selectedBill}
							onSuccess={() => {
								billEditDisclosure.onClose()
								refreshPageData()
								setSelectedBill(null)
							}}
						/>
					)}
					{selectedBill && isBillDeleteDialogOpen && (
						<BillDeleteDialogController
							isOpen={isBillDeleteDialogOpen}
							setIsOpen={setIsBillDeleteDialogOpen}
							bill={selectedBill}
							type={selectedItemType}
							onSuccess={() => {
								refreshPageData()
								setSelectedBill(null)
							}}
						/>
					)}
				</>
			)}
		</>
	)
}
