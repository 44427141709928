import { Box, Button, Flex, Tooltip, useDisclosure } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsJournalText, BsPrinter } from "react-icons/bs"
import { useParams, useSearchParams } from "react-router-dom"
import ReactToPrint from "react-to-print"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { SlipAddButton } from "src/components/shared/SlipAddButton"
import { SlipAddDrawers } from "src/components/shared/SlipAddDrawers"
import { ISubPartyMonthStatementV3 } from "src/domain/entities"
import { useAuth } from "src/hooks"
import { PartyDetailViewType } from "../../../utils/enums"
import { TabItem, TabMenu } from "../../shared/TabMenu"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { PartyDetailHeader } from "./PartyDetailHeader"
import {
	PartyDetailsPageContextProvider,
	usePartyDetailsPageContext,
} from "./PartyDetailsPageContext"
import { PartyMonthSectionController } from "./PartyMonth/PartyMonthSectionController"
import { PartyMonthColumnView } from "./PartyMonthColumnView"
import { PartyMonthSlipListController } from "./PartyMonthSlipList/PartyMonthSlipListController"
import { PartyMonthStatementController } from "./PartyMonthStatement/PartyMonthStatementController"
import { PartySubPartySectionController } from "./SubParty"
import { SubPartyMonthUpdateDrawerFormController } from "./SubPartyMonth/SubPartyMonthUpdateDrawerForm"

export const PartyDetailPage: FC = () => {
	const { id: partyId } = useParams<"id">() as { id: string }
	const [params] = useSearchParams()

	return (
		<PartyDetailsPageContextProvider partyId={partyId}>
			<DashboardWrapper>
				<PartyDetailPageController
					partyId={partyId}
					subPartyId={params.get("subPartyId")}
				/>
			</DashboardWrapper>
		</PartyDetailsPageContextProvider>
	)
}

const PartyDetailPageController: FC<{ partyId: string; subPartyId?: string | null }> = ({
	partyId,
	subPartyId,
}) => {
	const { token } = useAuth()
	const { statement, party, isPartyLoading, refreshPageData, selectedSubParty } =
		usePartyDetailsPageContext()

	const updateStatementNoteDrawerDisclosure = useDisclosure()

	const tabs: TabItem[] = Object.values(PartyDetailViewType).map((type) => ({
		child: type,
		value: type,
	}))
	const [selectedTab, setSelectedTab] = useState(tabs[0].value)
	const handleOnTabChange = useCallback((value: string) => {
		setSelectedTab(value)
	}, [])

	const initializePage = useCallback(() => {
		refreshPageData()
	}, [refreshPageData])

	let subPartyMonthStatementList: ISubPartyMonthStatementV3[] = []

	if (statement) {
		subPartyMonthStatementList = statement.subPartyMonthStatementList

		if (selectedSubParty) {
			subPartyMonthStatementList = statement.subPartyMonthStatementList.filter(
				(el) => el.subParty.id === selectedSubParty.id,
			)
		}
	}

	useEffect(() => {
		initializePage()
	}, [token, partyId, initializePage])

	const printComponentRef = useRef<HTMLDivElement>(null)

	const reactToPrintContent = useCallback(() => {
		return printComponentRef.current
	}, [])

	const getPageStyles = () => {
		return `
			@media all {
				.printable { display: none;}
			}
			@media print {
				.printable { display: block;}
				table { page-break-inside:auto }
				tr    { page-break-inside:avoid; page-break-after:auto }
				thead { display:table-header-group }
				tfoot { display:table-footer-group }
			}
		`
	}

	if (isPartyLoading) return <CenteredSpinner />
	if (!party) return null

	return (
		<Box>
			{/* Header section */}
			<PartyDetailHeader party={party} />
			<Flex>
				{/* Sidebar section */}
				<Box width="2xs">
					<PartySubPartySectionController
						partyId={partyId}
						subPartyId={subPartyId}
					/>
					<PartyMonthSectionController />
				</Box>

				<Box margin={2} width="full">
					<Flex justify="space-between" alignItems="center">
						{/* Tab menu section */}
						<TabMenu
							tabs={tabs}
							onTabChange={handleOnTabChange}
							selectedTab={selectedTab}
						/>
						<Box>
							{subPartyMonthStatementList[0] ? (
								<Tooltip label="This will reflect in the print of statement">
									<Button
										size="sm"
										variant="ghost"
										colorScheme="blue"
										leftIcon={<BsJournalText size={20} />}
										onClick={
											updateStatementNoteDrawerDisclosure.onOpen
										}
									>
										{subPartyMonthStatementList[0]?.subPartyMonth.note
											? "Update Note"
											: "Add Note"}
									</Button>
								</Tooltip>
							) : null}
							<ReactToPrint
								content={reactToPrintContent}
								copyStyles={true}
								trigger={() => (
									<Button
										size="sm"
										variant="ghost"
										colorScheme="green"
										leftIcon={<BsPrinter size={20} />}
									>
										Print
									</Button>
								)}
							/>
							<SlipAddButton />
						</Box>
					</Flex>
					<Box margin={1}>
						{selectedTab === PartyDetailViewType.STATEMENT && (
							// Statement Section
							<PartyMonthStatementController />
						)}
						{selectedTab === PartyDetailViewType.COLUMN && (
							// Statement Section
							<PartyMonthColumnView />
						)}
						{selectedTab === PartyDetailViewType.LIST && (
							<PartyMonthSlipListController />
						)}
					</Box>
					<Box ref={printComponentRef}>
						<style>{getPageStyles()}</style>
						<div className="printable">
							{selectedTab === PartyDetailViewType.STATEMENT ? (
								<>
									<PartyMonthStatementController />
									<div style={{ pageBreakBefore: "always" }}>
										<PartyMonthSlipListController />
									</div>
								</>
							) : null}
							{selectedTab === PartyDetailViewType.COLUMN ? (
								<PartyMonthColumnView />
							) : null}
							{selectedTab === PartyDetailViewType.LIST ? (
								<div style={{ pageBreakBefore: "always" }}>
									<PartyMonthSlipListController />
								</div>
							) : null}
						</div>
					</Box>
				</Box>
			</Flex>
			<SlipAddDrawers
				selectedParty={party}
				defaultSelectedSubParty={selectedSubParty}
				onSuccess={() => initializePage()}
			/>
			{subPartyMonthStatementList[0] ? (
				<SubPartyMonthUpdateDrawerFormController
					{...updateStatementNoteDrawerDisclosure}
					subPartyMonth={subPartyMonthStatementList[0].subPartyMonth}
					onSuccess={() => initializePage()}
				/>
			) : null}
		</Box>
	)
}
