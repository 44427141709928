import { FC, useCallback, useEffect, useRef } from "react"
import { IPartyV1, IProductV1, ISubPartyV1 } from "src/domain/entities"
import { BillAddDrawerFormController } from "../entries/bill/BillAddDrawerForm"
import { CashReceiptAddFormController } from "../entries/cashReceipt/CashReceiptAddDrawerForm"
import { JobWorkItemSlipAddDrawerFormController } from "../entries/itemSlip/JobWorkItemSlipAddDrawerForm/JobWorkItemSlipAddDrawerFormController"
import { JobWorkBillAddDrawerFormController } from "../entries/jobWorkBill/JobWorkBillAddDrawerForm/JobWorkBillAddDrawerFormController"
import { useGlobalContext } from "../pages/GlobalContext"

export const SlipAddDrawers: FC<{
	onSuccess?: () => void
	selectedParty?: IPartyV1
	defaultSelectedSubParty?: ISubPartyV1
	defaultSelectedProduct?: IProductV1
}> = ({ onSuccess, defaultSelectedSubParty, defaultSelectedProduct, selectedParty }) => {
	const {
		cashReceiptAddType,
		setCashReceiptAddType,
		cashReceiptAddDrawerDisclosure,
		jobWorkItemSlipAddType,
		jobWorkItemSlipAddDrawerDisclosure,
		jobWorkBillAddType,
		jobWorkBillAddDrawerDisclosure,
		billAddType,
		billAddDrawerDisclosure,
		subPartyList,
	} = useGlobalContext()

	const selectedSubParty = useRef(defaultSelectedSubParty)

	const setDefaultSelectedSubParty = useCallback(() => {
		if (defaultSelectedSubParty) {
			selectedSubParty.current = defaultSelectedSubParty
		} else if (subPartyList.length) {
			const findParty = subPartyList.find(
				(p) => p.party?.id === selectedParty?.id && p.isDefault,
			)
			if (findParty) {
				selectedSubParty.current = findParty
			}
		}
	}, [defaultSelectedSubParty, subPartyList, selectedParty])

	useEffect(() => {
		setDefaultSelectedSubParty()
	}, [setDefaultSelectedSubParty])

	return (
		<>
			{cashReceiptAddType && cashReceiptAddDrawerDisclosure?.isOpen && (
				<CashReceiptAddFormController
					{...cashReceiptAddDrawerDisclosure}
					type={cashReceiptAddType}
					defaultSelectedParty={selectedParty}
					onSuccess={() => {
						cashReceiptAddDrawerDisclosure.onClose()
						setCashReceiptAddType(null)
						onSuccess?.()
					}}
				/>
			)}
			{jobWorkItemSlipAddType && jobWorkItemSlipAddDrawerDisclosure?.isOpen && (
				<JobWorkItemSlipAddDrawerFormController
					{...jobWorkItemSlipAddDrawerDisclosure}
					type={jobWorkItemSlipAddType}
					defaultSelectedSubParty={selectedSubParty.current}
					defaultSelectedProduct={defaultSelectedProduct}
					onSuccess={() => {
						jobWorkItemSlipAddDrawerDisclosure.onClose()
						onSuccess?.()
					}}
				/>
			)}
			{jobWorkBillAddType && jobWorkBillAddDrawerDisclosure?.isOpen && (
				<JobWorkBillAddDrawerFormController
					{...jobWorkBillAddDrawerDisclosure}
					type={jobWorkBillAddType}
					defaultSelectedSubParty={selectedSubParty.current}
					defaultSelectedProduct={defaultSelectedProduct}
					onSuccess={() => {
						jobWorkBillAddDrawerDisclosure.onClose()
						onSuccess?.()
					}}
				/>
			)}
			{billAddType && billAddDrawerDisclosure?.isOpen && (
				<BillAddDrawerFormController
					{...billAddDrawerDisclosure}
					type={billAddType}
					defaultSelectedSubParty={selectedSubParty.current}
					defaultSelectedProduct={defaultSelectedProduct}
					onSuccess={() => {
						billAddDrawerDisclosure.onClose()
						onSuccess?.()
					}}
				/>
			)}
		</>
	)
}
