import { ComponentProps, FC, useCallback, useState } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { useGlobalContext } from "src/components/pages/GlobalContext"
import { purchaseBillAddService } from "src/domain/services/purchaseBill/purchaseBillAddService"
import { saleBillAddService } from "src/domain/services/saleBill/saleBillAddService"
import { useAuth } from "src/hooks"
import { FormikOnSubmit } from "src/utils/types"
import { BillAddDrawerFormView, IBillAddFormFields } from "."
import { IBillV2, IProductV1 } from "../../../../domain/entities"

interface Props
	extends Omit<
		ComponentProps<typeof BillAddDrawerFormView>,
		"handleSubmit" | "billType" | "productList" | "subPartyList"
	> {
	type: EItemType
	onSuccess: (bill: IBillV2) => void
}

export const BillAddDrawerFormController: FC<Props> = ({
	onSuccess,
	defaultSelectedSubParty,
	...rest
}) => {
	const { token } = useAuth()
	const { subPartyList, productList, isProductListLoading, fetchProductList } =
		useGlobalContext()

	const [defaultSelectedProduct, setDefaultSelectedProduct] = useState<IProductV1>()

	const handleOnSubmit = useCallback<FormikOnSubmit<IBillAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (rest.type === EItemType.PURCHASE_BILL) {
					const bill = await purchaseBillAddService(values, token)
					onSuccess(bill)
					rest.onClose()
				} else if (rest.type === EItemType.SALE_BILL) {
					const bill = await saleBillAddService(values, token)
					onSuccess(bill)
					rest.onClose()
				} else {
					throw new Error("Invalid Item type")
				}
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ note: message })
			}
		},
		[onSuccess, rest, token],
	)

	return (
		<BillAddDrawerFormView
			{...rest}
			billType={rest.type === EItemType.PURCHASE_BILL ? "purchase" : "sale"}
			subPartyList={subPartyList}
			handleSubmit={handleOnSubmit}
			productList={productList}
			isProductListLoading={isProductListLoading}
			defaultSelectedSubParty={defaultSelectedSubParty}
			onProductAddSuccess={() => fetchProductList()}
			defaultSelectedProduct={defaultSelectedProduct}
			setDefaultSelectedProduct={setDefaultSelectedProduct}
		/>
	)
}
