import {
	Flex,
	FormControl,
	Input,
	InputGroup,
	InputRightAddon,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC } from "react"
import { EItemType } from "src/components/entries/EItemType"
import { ErrorMessageField } from "src/components/ui"
import { DrawerForm } from "src/components/ui/DrawerForm"
import { InputLabel } from "src/components/ui/InputLabel"
import { IJobWorkItemSlipV2, IJobWorkItemV2 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { IItemSlipUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	slip: IJobWorkItemSlipV2
	jobWorkItem: IJobWorkItemV2
	type: EItemType
	handleSubmit: FormikOnSubmit<IItemSlipUpdateFormFields["update"]>
}

export const ItemSlipUpdateDrawerFormView: FC<Props> = ({
	slip,
	type,
	handleSubmit,
	...rest
}) => (
	<Formik<IItemSlipUpdateFormFields["update"]>
		initialValues={{
			rejectedQuantity: slip.rejectedQuantity,
			slipNo: slip.slipNo,
			prefix: slip.prefix,
			bagQuantity: slip.bagQuantity,
			grossQuantity: slip.grossQuantity,
			note: slip.note ?? "",
		}}
		onSubmit={handleSubmit}
		enableReinitialize={true}
	>
		{({ values, isSubmitting, handleChange }) => (
			<DrawerForm
				size="sm"
				headerLabel={getHeaderLabel(type)}
				submitLabel="Save"
				isSubmitting={isSubmitting}
				{...rest}
			>
				<Stack maxWidth={"sm"} marginX={"auto"}>
					<Flex>
						{/* Slip Prefix */}
						<FormControl flex={1} marginRight={2}>
							<InputLabel label="Slip Prefix" />
							<Input
								name="prefix"
								maxLength={10}
								value={values.prefix}
								onChange={handleChange}
							/>
						</FormControl>
						{/* Slip no */}
						<FormControl flex={2}>
							<InputLabel label="Slip No" />
							<Input
								name="slipNo"
								maxLength={10}
								required
								value={values.slipNo}
								onChange={handleChange}
							/>
						</FormControl>
					</Flex>
					<Flex>
						{/* Gross Quantity */}
						<FormControl flex={1}>
							<InputLabel label="Gross Quantity" />
							<InputGroup>
								<Input
									name="grossQuantity"
									required
									value={values.grossQuantity}
									onChange={handleChange}
								/>
								<InputRightAddon children="KG" />
							</InputGroup>
						</FormControl>
						{/* Bag Quantity */}
						<FormControl flex={1} marginLeft={2}>
							<InputLabel label="Bag Weight" />
							<InputGroup>
								<Input
									name="bagQuantity"
									value={values.bagQuantity}
									onChange={handleChange}
								/>
								<InputRightAddon children="KG" />
							</InputGroup>
						</FormControl>
					</Flex>
					{/* Rejection */}
					<FormControl flex={1} marginLeft={2}>
						<InputLabel label="Rejection" />
						<InputGroup>
							<Input
								name="rejectedQuantity"
								value={values.rejectedQuantity}
								onChange={handleChange}
							/>
							<InputRightAddon children="KG" />
						</InputGroup>
					</FormControl>
					{/* Note */}
					<FormControl>
						<InputLabel label="Note" suffixLabel="(Optional)" />
						<Textarea
							name="note"
							placeholder="Note"
							onChange={handleChange}
							value={values.note ?? ""}
						/>
						<ErrorMessage component={ErrorMessageField} name="note" />
					</FormControl>
				</Stack>
			</DrawerForm>
		)}
	</Formik>
)

function getHeaderLabel(type: EItemType) {
	switch (type) {
		case EItemType.INWARD:
			return "Update In Slip"
		case EItemType.OUTWARD:
			return "Update Out Slip"
	}
	return "Update Item Slip"
}
