import {
	ComponentProps,
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useState,
} from "react"
import { useGlobalContext } from "src/components/pages/GlobalContext"
import { IJobWorkItemSlipV2, IProductV1 } from "src/domain/entities"
import { itemSlipNextNoGetService } from "src/domain/services/itemSlip/itemSlipNextNoGetService"
import { jobWorkItemSlipAddService } from "src/domain/services/jobWorkItem/slip/jobWorkItemSlipAddService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IJobWorkItemSlipAddFormFields } from "./IJobWorkItemSlipAddFormFields"
import { JobWorkItemSlipAddDrawerFormView } from "./JobWorkItemSlipAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof JobWorkItemSlipAddDrawerFormView>,
		| "handleSubmit"
		| "fetchSlipNextNo"
		| "productList"
		| "selectedProduct"
		| "fetchProductList"
		| "fetchPartyList"
		| "itemType"
		| "subPartyList"
		| "productList"
	> {
	onSuccess: (itemSlip: IJobWorkItemSlipV2) => void
}

export const JobWorkItemSlipAddDrawerFormController: FC<Props> = ({
	onSuccess,
	defaultSelectedSubParty,
	...rest
}) => {
	const {
		token,
		company: { defaultPrefix },
	} = useAuth()
	const {
		subPartyList,
		fetchSubPartyList,
		productList,
		isProductListLoading,
		fetchProductList,
	} = useGlobalContext()
	const [defaultSelectedProduct, setDefaultSelectedProduct] = useState<IProductV1>()

	const fetchSlipNextNo = useCallback(
		async (
			setNextSlipNo: Dispatch<SetStateAction<number>>,
			payload: { issueDate: string; prefix: string },
		) => {
			const response = await itemSlipNextNoGetService(payload, token)
			setNextSlipNo(response.nextSlipNo)
			setDefaultSelectedProduct?.(defaultSelectedProduct)
		},
		[token, setDefaultSelectedProduct, defaultSelectedProduct],
	)

	const handleSubmit = useCallback<FormikOnSubmit<IJobWorkItemSlipAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const itemSlip = await jobWorkItemSlipAddService(values, token)
				onSuccess(itemSlip)
				rest.onClose()
			} catch (err) {
				setErrors({ note: getErrorMessage(err) })
			}
		},
		[onSuccess, rest, token],
	)

	return (
		<JobWorkItemSlipAddDrawerFormView
			{...rest}
			subPartyList={subPartyList}
			productList={productList}
			isProductListLoading={isProductListLoading}
			defaultSelectedSubParty={defaultSelectedSubParty}
			defaultPrefix={defaultPrefix ?? "SL"}
			fetchSlipNextNo={fetchSlipNextNo}
			handleSubmit={handleSubmit}
			onProductAddSuccess={fetchProductList}
			onPartyAddSuccess={fetchSubPartyList}
			defaultSelectedProduct={defaultSelectedProduct}
			setDefaultSelectedProduct={setDefaultSelectedProduct}
		/>
	)
}
