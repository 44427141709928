import {
	Badge,
	Flex,
	IconButton,
	Input,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import moment from "moment"
import { FC, useState } from "react"
import { BsCheck, BsX } from "react-icons/bs"
import { EditIconButton } from "src/components/ui/iconButtons/EditIconButton"
import { ICompanyV2 } from "src/domain/entities"

export const CompanyListView: FC<{
	list: ICompanyV2[]
	handleSubmit: (company: ICompanyV2, date: string) => void
	editingCompanyId?: string
	setEditingCompanyId: (id?: string) => void
}> = ({ list, handleSubmit, setEditingCompanyId, editingCompanyId }) => {
	const [dateValue, setDateValue] = useState<string>("")
	return (
		<Table>
			<Thead>
				<Tr>
					<Th>Name</Th>
					<Th>Mobile No.</Th>
					<Th>Expiry Date</Th>
					<Th>Status</Th>
					<Th>Registration Date</Th>
					<Th isNumeric>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{list.map((company, i) => {
					const isEditing = company.id === editingCompanyId
					const expiresAt = company.expiresAt
						? moment(company.expiresAt).format("DD-MM-Y")
						: "-"

					return (
						<Tr key={i}>
							<Td>{company.name}</Td>
							<Td>{company.user?.verifiedMobile?.mobileNo}</Td>
							<Td>
								{isEditing ? (
									<Input
										maxWidth={"fit-content"}
										type={"date"}
										value={dateValue}
										onChange={(e) => setDateValue(e.target.value)}
										defaultValue={company.expiresAt ?? moment.now()}
									/>
								) : (
									expiresAt
								)}
							</Td>
							<Td>
								{company.isExpired ? (
									<Badge colorScheme={"red"}>Expired</Badge>
								) : (
									<Badge colorScheme={"green"}>Active</Badge>
								)}
							</Td>
							<Td>{moment(company.createdAt).format("DD-MM-Y h:mm A")}</Td>
							<Td>
								{isEditing ? (
									<Flex>
										<IconButton
											aria-label="submit"
											variant="ghost"
											icon={<BsCheck size={20} />}
											size={"sm"}
											mx="1"
											colorScheme={"green"}
											onClick={() =>
												handleSubmit(company, dateValue)
											}
										/>
										<IconButton
											aria-label="cancel"
											variant="ghost"
											icon={<BsX size={20} />}
											size={"sm"}
											mx="1"
											colorScheme={"red"}
											onClick={() => setEditingCompanyId(undefined)}
										/>
									</Flex>
								) : (
									<EditIconButton
										onClick={() => {
											setDateValue(
												moment(company.expiresAt).format(
													"Y-MM-DD",
												),
											)
											setEditingCompanyId(company.id)
										}}
									/>
								)}
							</Td>
						</Tr>
					)
				})}
			</Tbody>
		</Table>
	)
}
