import {
	FormControl,
	Input,
	InputGroup,
	InputLeftAddon,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import moment from "moment"
import { ComponentProps, FC } from "react"
import { ReactSelect, SelectOption } from "src/components/shared/ReactSelect"
import { DrawerForm } from "src/components/ui"
import { InputDescription } from "src/components/ui/InputDescription"
import { InputLabel } from "src/components/ui/InputLabel"
import { IPartyV1 } from "src/domain/entities"
import { FormikOnSubmit } from "src/utils/types"
import { ErrorMessageField } from "../../../ui/ErrorMessageField"
import { EItemType } from "../../EItemType"
import { ICashReceiptAddFormFields } from "./ICashReceiptAddFormFields"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	type: EItemType
	defaultSelectedParty?: IPartyV1
	partyList: IPartyV1[]
	handleSubmit: FormikOnSubmit<ICashReceiptAddFormFields>
}

export const CashReceiptAddDrawerFormView: FC<Props> = ({
	type,
	handleSubmit,
	partyList,
	defaultSelectedParty,
	...rest
}) => {
	const issueDate = moment().format("YYYY-MM-DD")

	const partyDescription = `Party ${
		type === EItemType.INWARD_CASH
			? "from where cash came in"
			: "to whom cash has gone out"
	}`

	let headerLabel = "Add Cash Receipt"

	if (type === EItemType.INWARD_CASH) {
		headerLabel = "Add Received Cash Receipt"
	} else if (type === EItemType.OUTWARD_CASH) {
		headerLabel = "Add Paid Cash Receipt"
	} else {
		headerLabel = "Invalid Item type"
	}

	return (
		<Formik<ICashReceiptAddFormFields>
			initialValues={{
				partyId: defaultSelectedParty?.id ?? "",
				amount: 0,
				note: "",
				issueDate,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => (
				<DrawerForm
					size="sm"
					headerLabel={headerLabel}
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"sm"} marginX={"auto"}>
						{/* Party */}
						<FormControl>
							<InputLabel label="Party" />
							<ReactSelect
								name="partyId"
								onChange={(newValue) => {
									setFieldValue(
										"partyId",
										(newValue as SelectOption).value,
									)
								}}
								options={partyList.map((party) => ({
									label: party.name,
									value: party.id,
								}))}
								isSearchable
								defaultValue={defaultSelectedParty?.id}
							/>
							<InputDescription>{partyDescription}</InputDescription>
						</FormControl>
						{/* Issue date */}
						<FormControl>
							<InputLabel label="Issue Date" />
							<Input
								name="issueDate"
								type="date"
								value={values.issueDate}
								onChange={handleChange}
							/>
						</FormControl>

						{/* Amount */}
						<FormControl flex={1} marginLeft={2}>
							<InputLabel label="Amount" />
							<InputGroup>
								<InputLeftAddon children="₹" />
								<Input
									name="amount"
									required
									value={values.amount}
									onChange={handleChange}
								/>
							</InputGroup>
						</FormControl>

						{/* Note */}
						<FormControl>
							<InputLabel label="Note" suffixLabel="(Optional)" />
							<Textarea
								name="note"
								placeholder="Note"
								onChange={handleChange}
								value={values.note ?? ""}
							/>
							<ErrorMessage component={ErrorMessageField} name="note" />
						</FormControl>
					</Stack>
				</DrawerForm>
			)}
		</Formik>
	)
}
