import {
	Box,
	Flex,
	FormControl,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Stack,
	Textarea,
} from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import moment from "moment"
import { ComponentProps, FC } from "react"
import {
	getProductOption,
	ProductAddButton,
} from "src/components/shared/ProductAddButton"
import { DrawerForm } from "src/components/ui"
import { InputLabel } from "src/components/ui/InputLabel"
import { FormikOnSubmit } from "src/utils/types"
import { IBillAddFormFields } from "."
import { IProductV1, ISubPartyV1 } from "../../../../domain/entities"
import { ReactSelect, SelectOption } from "../../../shared/ReactSelect"
import { ErrorMessageField } from "../../../ui/ErrorMessageField"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	subPartyList: ISubPartyV1[]
	productList: IProductV1[]
	isProductListLoading?: boolean
	defaultSelectedSubParty?: ISubPartyV1
	defaultSelectedProduct?: IProductV1
	billType: "purchase" | "sale"
	onProductAddSuccess?: Function
	setDefaultSelectedProduct?: Function
	handleSubmit: FormikOnSubmit<IBillAddFormFields>
}

export const BillAddDrawerFormView: FC<Props> = ({
	subPartyList,
	productList,
	isProductListLoading,
	defaultSelectedSubParty,
	defaultSelectedProduct,
	billType,
	handleSubmit,
	onProductAddSuccess,
	setDefaultSelectedProduct,
	...rest
}) => {
	const issueDate = moment().format("YYYY-MM-DD")
	const headerLabel = billType === "sale" ? "Add Sale Bill" : "Add Purchase Bill"
	const subPartyLabel = billType === "purchase" ? "Select Supplier" : "Select Recipient"

	function getSubPartyLabel(subPartyId: string) {
		const subParty = subPartyList.find((subParty) => subParty.id === subPartyId)
		if (!subParty) return "unknown"
		const partyName = !subParty.isDefault ? `(${subParty.party?.name})` || "" : ""
		const name = `${subParty.name} ${partyName}`
		return name
	}

	return (
		<Formik<IBillAddFormFields>
			initialValues={{
				subPartyId: defaultSelectedSubParty?.id ?? "",
				productId: defaultSelectedProduct?.id ?? "",
				quantity: 0,
				saleRate: 0,
				note: "",
				fullBillNo: "",
				workRate: 0,
				issueDate,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => (
				<DrawerForm
					size="sm"
					headerLabel={headerLabel}
					submitLabel="Save"
					isSubmitting={isSubmitting}
					{...rest}
				>
					<Stack maxWidth={"sm"} marginX={"auto"}>
						{/* Sub Party */}
						<FormControl>
							<InputLabel label={subPartyLabel} />
							<ReactSelect
								name="subPartyId"
								onChange={(newValue) => {
									setFieldValue(
										"subPartyId",
										(newValue as SelectOption).value,
									)
								}}
								options={subPartyList.map((subParty) => ({
									label: getSubPartyLabel(subParty.id),
									value: subParty.id,
								}))}
								isSearchable
								defaultValue={values.subPartyId}
							/>
						</FormControl>
						{/* Issue date */}
						<FormControl>
							<InputLabel label="Issue Date" />
							<Input
								name="issueDate"
								type="date"
								value={values.issueDate}
								onChange={handleChange}
							/>
						</FormControl>

						{/* Full Bill no */}
						<FormControl flex={2}>
							<InputLabel label="Bill No" />
							<Input
								name="fullBillNo"
								placeholder="Bill No."
								maxLength={20}
								required
								value={values.fullBillNo}
								onChange={handleChange}
							/>
						</FormControl>

						<Flex alignItems="flex-end">
							{/* Product */}
							<FormControl flex={1}>
								<InputLabel label="Select Product" />
								<ReactSelect
									name="productId"
									onChange={(newValue) => {
										setFieldValue(
											"productId",
											(newValue as SelectOption).value,
										)
									}}
									value={getProductOption(
										values.productId,
										productList,
									)}
									isLoading={isProductListLoading}
									options={productList.map((product) => ({
										label: product.name,
										value: product.id,
									}))}
									isSearchable
									defaultValue={defaultSelectedProduct?.id}
								/>
							</FormControl>
							<Box marginLeft={2}>
								<ProductAddButton
									onSuccess={async (product) => {
										await onProductAddSuccess?.()
										setDefaultSelectedProduct?.(product)
										setFieldValue("productId", product.id)
									}}
								/>
							</Box>
						</Flex>

						{/* Quantity */}
						<FormControl flex={1}>
							<InputLabel label="Quantity" />
							<InputGroup>
								<Input
									name="quantity"
									required
									value={values.quantity}
									onChange={handleChange}
								/>
								<InputRightAddon children="KG" />
							</InputGroup>
						</FormControl>

						<Flex>
							{/* Labour Rate */}
							<FormControl flex={1}>
								<InputLabel label="Labour Rate" suffixLabel="(per KG)" />
								<InputGroup>
									<InputLeftAddon children="₹" />
									<Input
										name="workRate"
										required
										value={values.workRate}
										onChange={handleChange}
									/>
								</InputGroup>
							</FormControl>

							{/* Sale Rate */}
							<FormControl flex={1} marginLeft={2}>
								<InputLabel label="Market Rate" suffixLabel="(per KG)" />
								<InputGroup>
									<InputLeftAddon children="₹" />
									<Input
										name="saleRate"
										required
										value={values.saleRate}
										onChange={handleChange}
									/>
								</InputGroup>
							</FormControl>
						</Flex>

						{/* Note */}
						<FormControl>
							<InputLabel label="Note" suffixLabel="(Optional)" />
							<Textarea
								name="note"
								placeholder="Note"
								onChange={handleChange}
								value={values.note ?? ""}
							/>
							<ErrorMessage component={ErrorMessageField} name="note" />
						</FormControl>
					</Stack>
				</DrawerForm>
			)}
		</Formik>
	)
}
