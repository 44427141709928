import { ComponentProps, FC, useCallback, useEffect, useState } from "react"
import { useGlobalContext } from "src/components/pages/GlobalContext"
import { IJobWorkBillV2, IProductV1 } from "src/domain/entities"
import { inwardJobWorkBillAddService } from "src/domain/services/inwardJobWorkBill/inwardJobWorkBillAddService"
import { outwardJobWorkBillAddService } from "src/domain/services/outwardJobWorkBill/outwardJobWorkBillAddService"
import { useAuth } from "src/hooks"
import { getErrorMessage } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { EItemType } from "../../EItemType"
import { IJobWorkBillAddFormFields } from "./IJobWorkBillAddFormFields"
import { JobWorkBillAddDrawerFormView } from "./JobWorkBillAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof JobWorkBillAddDrawerFormView>,
		| "handleSubmit"
		| "fetchSlipNextNo"
		| "productList"
		| "selectedProduct"
		| "itemType"
		| "subPartyList"
		| "productList"
	> {
	onSuccess: (inwardJobWorkBill: IJobWorkBillV2) => void
}

export const JobWorkBillAddDrawerFormController: FC<Props> = ({
	onSuccess,
	type,
	defaultSelectedSubParty,
	...rest
}) => {
	const { token } = useAuth()
	const { subPartyList, productList, isProductListLoading, fetchProductList } =
		useGlobalContext()

	useEffect(() => {
		fetchProductList()
	}, [fetchProductList])

	const handleSubmit = useCallback<FormikOnSubmit<IJobWorkBillAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (type === EItemType.INWARD_JOBWORK_BILL) {
					const inwardJobWorkBill = await inwardJobWorkBillAddService(
						values,
						token,
					)
					onSuccess(inwardJobWorkBill)
				} else if (type === EItemType.OUTWARD_JOBWORK_BILL) {
					const outwardJobWorkBill = await outwardJobWorkBillAddService(
						values,
						token,
					)
					onSuccess(outwardJobWorkBill)
				} else {
					throw new Error("Invalid item type")
				}
				rest.onClose()
			} catch (err) {
				setErrors({ note: getErrorMessage(err) })
			}
		},
		[onSuccess, rest, token, type],
	)

	const [defaultSelectedProduct, setDefaultSelectedProduct] = useState<IProductV1>()

	return (
		<JobWorkBillAddDrawerFormView
			{...rest}
			subPartyList={subPartyList}
			productList={productList}
			isProductListLoading={isProductListLoading}
			type={type}
			defaultSelectedSubParty={defaultSelectedSubParty}
			handleSubmit={handleSubmit}
			onProductAddSuccess={() => fetchProductList()}
			defaultSelectedProduct={defaultSelectedProduct}
			setDefaultSelectedProduct={setDefaultSelectedProduct}
		/>
	)
}
