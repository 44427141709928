import { FC } from "react"
import { CenteredSpinner } from "src/components/shared/CenteredSpinner"
import { usePartyDetailsPageContext } from "../PartyDetailsPageContext"
import { PartyMonthSectionView } from "./PartyMonthSectionView"

export const PartyMonthSectionController: FC = () => {
	const {
		partyMonthList,
		isPartyMonthListLoading,
		selectedPartyMonth,
		setSelectedPartyMonth,
	} = usePartyDetailsPageContext()

	if (isPartyMonthListLoading) return <CenteredSpinner />

	return (
		<PartyMonthSectionView
			list={partyMonthList}
			selectedPartyMonth={selectedPartyMonth}
			setSelectedPartyMonth={setSelectedPartyMonth}
		/>
	)
}
